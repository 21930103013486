
  import { Action, Getter } from "vuex-class";
  import { Component, Watch, Mixins, Ref } from "vue-property-decorator";
  import { FormBase, FormInput, FormCheckbox, FormSelect, FormSubmit, FormOriginInput } from "@/components/forms";
  import { bus } from "@/main";
  import { generateEmptyPropertyForActivity } from "@/utils/model-helpers";
  import { ActivityAccount, CreateActivityAccountPayload, TeamFunctions } from "@/store/modules/activity-account.store";
  import { HasFormErrors } from "@/mixins/has-form-errors";
  import { ActivityOrigin } from "@/store/modules/activity.store";
  import { IndexAccountPayload } from "@/store/modules/account.store";
  import { Debounce } from "@/utils/vue-helpers";

  interface SelectOption {
    label: string;
    value: string;
  }

  interface CreateActivityModalOptions {
    showProspectToggle: boolean;
    redirectToProperty: boolean;
    callback?: (activity_id: number) => void;
  }

  @Component({
    components: {
      LayoutModal: () => import("@/layouts/components/modal.vue"),
      FormBase,
      FormInput,
      FormSelect,
      FormOriginInput,
      FormCheckbox,
      FormSubmit,
    },
  })
  export default class ActivityCreateModal extends Mixins(HasFormErrors) {
    @Getter("auth/me") me!: CurrentAccount;

    @Action("activity/create") createActivity!: ActivityCreate;
    @Action("activity/delete") deleteActivity!: (payload: number) => Promise<void>;
    @Action("activity/setViewing") activitySetViewing!: (activity: Activity) => void;
    @Action("property/setViewing") propertySetViewing!: (property: Property) => void;
    @Action("property/create") createProperty!: (payload: PropertyCreatePayload) => Promise<Property>;
    @Action("activity-account/create") createActivityAccount!: (payload: CreateActivityAccountPayload) => Promise<ActivityAccount>;
    @Action("account/indexForCurrentOffice") indexAccounts!: (payload: IndexAccountPayload) => Promise<Account[]>;

    @Ref() readonly streetInput?: FormInput;

    payload: ActivityPayload = {
      name: "",
      origin: {
        origin: null,
      },
      won: false,
      status: "OPEN",
      fixed_commission: null,
      relative_commission: null,
      commission_vat_inclusive: null,
    };

    representative_id: number | null = null;

    address: Address = {
      street: "",
      number: "",
      additional: "",
      city: "",
      zip: "",
      country: "BE",
    };

    country = {
      value: "BE",
      label: "België",
    };

    countries: SelectOption[] = [
      {
        value: "BE",
        label: "België",
      },
    ];

    propertyType: "HOUSE" | "APPARTMENT" | "LAND" | "OFFICE" | "COMMERCIAL" | "INDUSTRIAL" | "PARKING" | "" = "";

    propertyTypes: SelectOption[] = [
      {
        value: "HOUSE",
        label: this.$t("property.types.HOUSE") as string,
      },
      {
        value: "APPARTMENT",
        label: this.$t("property.types.APPARTMENT") as string,
      },
      {
        value: "LAND",
        label: this.$t("property.types.LAND") as string,
      },
      {
        value: "COMMERCIAL",
        label: this.$t("property.types.COMMERCIAL") as string,
      },
      {
        value: "PARKING",
        label: this.$t("property.types.PARKING") as string,
      },
    ];

    localRepresentatives: Account[] = [];

    options: CreateActivityModalOptions = {
      showProspectToggle: true,
      redirectToProperty: true,
    };

    show: boolean = false;

    get originOptions() {
      return Object.values(ActivityOrigin).map((a) => {
        return {
          value: a,
          label: this.$t(`views.dossier.origin.${a}`),
        };
      });
    }

    get representatives() {
      return Object.values(this.localRepresentatives).map((account) => {
        return {
          value: account.id,
          label: account.first_name + " " + account.last_name,
        };
      });
    }

    mounted() {
      bus.$off("show-activity-create");
      bus.$on("show-activity-create", (options: CreateActivityModalOptions) => {
        this.show = true;
        this.clearFormValues();
        this.clearErrors();
        this.fetchRepresentatives();
        this.representative_id = this.me.id;
        this.options = { ...this.options, ...options };
      });
    }

    async submit(form: FormClass) {
      // TODO: Fix ugly code
      if (this.$route.matched.map((r) => r.name).includes("files") || this.$route.matched.map((r) => r.name).includes("prospects")) {
        this.payload.dont_save = true;
      }

      let activity = null;
      try {
        activity = await this.createActivity(this.payload);

        if (!this.propertyType) {
          return;
        }

        const propertyPayload = generateEmptyPropertyForActivity(activity, this.propertyType);

        const property = await this.createProperty({ ...propertyPayload, address: this.address });

        const activityAccount = await this.createActivityAccount({ account_id: Number(this.representative_id), activity_id: activity.id, type: TeamFunctions.buyer });

        const representative = this.localRepresentatives.find((e) => Number(e.id) == Number(this.representative_id));
        if (representative) {
          activity.assigned_to = [
            {
              id: Number(this.representative_id),
              relation_id: activityAccount.id,
              media: representative.media,
              first_name: representative.first_name,
              last_name: representative.last_name,
              phone: representative.phone,
              email: representative.email,
              type: TeamFunctions.buyer,
            },
          ];
        }

        if (this.options.callback && typeof this.options.callback === "function") {
          this.options.callback(activity.id);
        }

        this.show = false;

        this.$toast.open({ message: this.$t("views.prospects.added") as string, type: "success", position: "bottom-right" });

        if (!this.options.redirectToProperty) {
          return;
        }

        this.activitySetViewing(activity);

        this.propertySetViewing(property);

        this.$router.push({ name: "prospect-property-form", params: { id: activity.id.toString() }, query: { type: "prospect" } });
      } catch (e) {
        this.errorResponse = this.formatErrors(e);

        if (activity) {
          this.deleteActivity(activity.id);
        }
      } finally {
        form.reset();
      }
    }

    private clearFormValues() {
      this.payload = {
        name: "",
        origin: {
          origin: null,
        },
        won: false,
        status: "OPEN",
        fixed_commission: null,
        relative_commission: null,
        commission_vat_inclusive: null,
      };
      this.address = {
        street: "",
        number: "",
        additional: "",
        city: "",
        zip: "",
        country: "BE",
      };
      this.options = {
        showProspectToggle: true,
        redirectToProperty: true,
      };

      this.representative_id = this.me.id;
    }

    async fetchRepresentatives() {
      this.localRepresentatives = await this.indexAccounts({ q: { or: { roles: ["administrator", "user"] } }, per_page: 99 });
    }

    @Watch("address", { deep: true })
    handleAddressChanged() {
      this.payload.name = `${this.address.zip} ${this.address.city} - ${this.address.street} ${this.address.number}${this.address.additional ? " " + this.address.additional : ""}`;
    }
  }
